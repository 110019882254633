export const BrandLog = () => {
    console.log(
        `
        ** **                                                                                          ** **
         ** **                                                                                        ** **
            **************      ****************    ************    ************    ****    ************
            ************  *     **** ****** *  *    ************    *  *********    *  *    *  *********
            ****        * *     ****        *  *    ****            *  *            *  *    *  *
            ****        * *     ****        *  *    ****            *  *            *  *    *  *
            ************  *     ****        *  *    ************    *  *********    *  *    *  *********
            ************* *     ****        *  *    ************    *********  *    *  *    *********  *
            ****                ****        *  *    ****                    *  *    *  *            *  *
            ****                ****        *  *    ****                    *  *    *  *            *  *
            ****                **** *******   *    ****            *********  *    *  *    *********  *
            ****                ***************     ****            ************    ****    ************

        ****************************************************************************************************
        ****************************************************************************************************
        `
    )
}