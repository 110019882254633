import { useState } from "react"

export const NotifState = () => {
    const [openNotif, setOpenNotif] = useState(!("Notification" in window) ? false:true)
    const [allowNotif, setAllowNotif] = useState(false)
    const [notif, setNotif] = useState({all: { all: [], read: [] }, unread: []});
   
    return {
        openNotif: {data: openNotif, set: setOpenNotif},
        allowNotif: {data: allowNotif, set: setAllowNotif},
        notif: {data: notif, set: setNotif}
    }
}