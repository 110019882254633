import { Box, Button } from "@mui/material"
import { UploadIcon } from "../../../../../core/global/Icons"
import { Close } from "@mui/icons-material"
import { useDropzone } from "react-dropzone"

export const Thumbnail = ({artcl}) => {
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: {'image/jpeg':[], 'image/png':[]},
        maxSize: 5000000,
        onDrop: acceptedFiles => {
            if (acceptedFiles.length > 0) {
                acceptedFiles.forEach(file => {
                    artcl.set(prevState => ({...prevState,
                        form: {...prevState.form,
                            inputs: {...prevState.form.inputs,
                                file: {
                                    value: acceptedFiles,
                                    object: URL.createObjectURL(file),
                                    name: file.name,
                                    type: file.type,
                                    size: file.size
                                }
                            }
                        }
                    }));
                });
              }
          }
    });

    const clearHandler = () => {
        artcl.set(prevState => ({...prevState,
            form: {...prevState.form,
                inputs: {...prevState.form.inputs,
                    file: {value: null, object: null, name: '', type: '', size: 0, updated: false, stat: false, msg: ''},
                }
            }
        }));
    }

    return (
        <Box width="100%" borderRadius="6px" border="1px solid #E7E8EA" p="16px"> 
            {
                artcl.data.form.inputs.file.object === null ?
                    <Box {...getRootProps({ className: 'dropzone' })} width="100%" borderRadius="6px" border={`1px dashed ${fileRejections.length > 0 ? 'red' : '#CACDD2'}`} display='flex' alignItems="center" justifyContent="center" flexDirection="column" pt="34px" pb="50px">
                        <input {...getInputProps()} />
                        <UploadIcon width="50px" height="49px" />
                        <Box fontSize="12px" color="283745" mt="12px" mb="8px">Drag and drop photo files to upload File Supported: JPEG, PNG</Box>
                        <Box fontSize="10px" mb="24px" color={fileRejections.length > 0 ? 'red' : '283745'}> Maximum size: 5 MB</Box>
                        <Button variant='contained' disableElevation sx={{fontSize:'12px', background:'#295F47' }}>Select Files</Button>
                    </Box>
                :
                    <Box display="flex" flexDirection="column" width="100%">
                        <Box 
                            sx={{
                                backgroundImage: `url(${artcl.data.form.inputs.file.object})`,
                                backgroundSize:'cover',
                                backgroundPosition:'center',
                                height:'252px',
                                width:'100%',
                                borderRadius:'6px'
                            }}
                        >   
                            <Box width="100%" display="flex" alignItems="center" justifyContent="end" p="8px">
                                <Box onClick={() => clearHandler()} bgcolor="#FFFFFF" borderRadius="100px" p="8px" display="flex" alignItems="center" boxShadow='0px 0px 4px rgba(0, 0, 0, 0.25)' sx={{cursor:'pointer', background: 'rgba(255, 255, 255, 0.50)', '&:hover':{background:'#FFFFFF'}}}>
                                    <Close sx={{fontSize:'16px'}}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
            }
        </Box>
    )
}