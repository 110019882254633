// Libraries
import React from 'react';
import ReactDOM from 'react-dom';

import { Index as IndexBusiness } from './__business'
import { Index as IndexPersonal } from './__personal'
// import { Index as IndexMain } from './__main/Index'
import { env } from './core/Env';

const loc = window.location.pathname
const subdir = loc.split('/')[1]
let Core

if (subdir === 'psl') {
	Core = IndexPersonal
} else if(subdir === 'biz'){
	Core = IndexBusiness
} else{
	let reglink;

	if (env() === 'prod') {
		reglink = 'https://sales.pofsis.com/psl/ptx/home';
	} else if (env() === 'sb') {
		reglink = 'https://sb-sales.pofsis.com/psl/ptx/home';
	} else if (env() === 'dev') {
		reglink = 'https://dev-sales.pofsis.com/psl/ptx/home';
	} else if (env() === 'local') {
		reglink = 'https://dev-sales.pofsis.com/psl/ptx/home';
	}

	window.location.replace(reglink);
}

ReactDOM.render(
<Core/>, document.getElementById('root'));		