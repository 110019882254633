export const SwitchingArrowIcons = () => {
    return(
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 3L16.6666 6.33333L13.3333 9.66667" stroke="#283745" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.33325 6.33398H16.6666" stroke="#283745" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.66659 11.334L3.33325 14.6673L6.66659 18.0007" stroke="#283745" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.33325 14.666H10.8333" stroke="#283745" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const SwitchingAcctIcons = () => {
    return(
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.45825 6.75065C6.45825 6.28555 6.54986 5.82501 6.72785 5.39531C6.90583 4.96562 7.16671 4.57519 7.49558 4.24631C7.82446 3.91744 8.21489 3.65656 8.64458 3.47858C9.07428 3.30059 9.53482 3.20898 9.99992 3.20898C10.465 3.20898 10.9256 3.30059 11.3553 3.47858C11.785 3.65656 12.1754 3.91744 12.5043 4.24631C12.8331 4.57519 13.094 4.96562 13.272 5.39531C13.45 5.82501 13.5416 6.28555 13.5416 6.75065C13.5416 7.68996 13.1684 8.5908 12.5043 9.25499C11.8401 9.91918 10.9392 10.2923 9.99992 10.2923C9.06061 10.2923 8.15977 9.91918 7.49558 9.25499C6.83139 8.5908 6.45825 7.68996 6.45825 6.75065ZM9.99992 4.45898C9.39213 4.45898 8.80924 4.70043 8.37947 5.1302C7.94969 5.55997 7.70825 6.14286 7.70825 6.75065C7.70825 7.35844 7.94969 7.94133 8.37947 8.3711C8.80924 8.80088 9.39213 9.04232 9.99992 9.04232C10.6077 9.04232 11.1906 8.80088 11.6204 8.3711C12.0501 7.94133 12.2916 7.35844 12.2916 6.75065C12.2916 6.14286 12.0501 5.55997 11.6204 5.1302C11.1906 4.70043 10.6077 4.45898 9.99992 4.45898Z" fill="#283745"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.66675 12.791C6.16947 12.791 5.69255 12.9886 5.34092 13.3402C4.98929 13.6918 4.79175 14.1687 4.79175 14.666V15.656C4.79175 15.671 4.80258 15.6843 4.81758 15.6868C8.25008 16.2468 11.7509 16.2468 15.1826 15.6868C15.1899 15.6857 15.1965 15.6819 15.2012 15.6762C15.206 15.6706 15.2085 15.6634 15.2084 15.656V14.666C15.2084 14.1687 15.0109 13.6918 14.6592 13.3402C14.3076 12.9886 13.8307 12.791 13.3334 12.791H13.0501C13.0277 12.7908 13.0055 12.7942 12.9842 12.801L12.2634 13.0368C10.7927 13.517 9.20744 13.517 7.73675 13.0368L7.01508 12.801C6.99434 12.7944 6.97269 12.791 6.95091 12.791H6.66675ZM3.54175 14.666C3.54175 13.8372 3.87099 13.0424 4.45704 12.4563C5.04309 11.8703 5.83795 11.541 6.66675 11.541H6.95008C7.10425 11.541 7.25758 11.566 7.40341 11.6127L8.12508 11.8485C9.34345 12.2462 10.6567 12.2462 11.8751 11.8485L12.5967 11.6127C12.7426 11.5652 12.8959 11.541 13.0492 11.541H13.3334C14.1622 11.541 14.9571 11.8703 15.5431 12.4563C16.1292 13.0424 16.4584 13.8372 16.4584 14.666V15.656C16.4584 16.2843 16.0034 16.8193 15.3834 16.9202C11.8181 17.5022 8.18204 17.5022 4.61675 16.9202C4.31668 16.8713 4.04378 16.7173 3.84684 16.4857C3.6499 16.2541 3.54175 15.96 3.54175 15.656V14.666Z" fill="#283745"/>
        </svg>
    )
}