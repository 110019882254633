import { useState } from "react"

export const LibraryState = () => {
    const [review, setReview] = useState({
        list: [],
        toggled: false,
    });
    const [artcl, setArtcl] = useState({
        list:[],
        form:{
            toggled: false, 
			submitted: false,
			method: '', 
            inputs: {
                file: {value: null, object: null, name: '', type: '', size: 0, updated: false, stat: false, msg: ''},
                title: {value: '', stat: true, msg: ''},
                description: {value: '', stat: true, msg: ''},
                category: {value: '', stat: true, msg: '', list: []},
                taxType: {value: '', stat: true, msg: '', list: []},
                reference: {value: '', stat: true, msg: ''},
                pname: {value: '', stat: true, msg: ''},
                seriesNum: {value: '', stat: true, msg: ''},
                status: {value: 2, stat: true, msg: ''},
                id: {value: '', stat: true, msg: ''},
                static_series: {value: '', stat: true, msg: ''},
            }
        },
        alert: {
            toggled: false,
            type: '',
            msg: '',
        },
        confirmation: {
            type:'',
            toggled: false,
            method: '',
            submitted: false,
            data: null
        },
        review:{
            toggled: false,
            data: null
        }
    });

    return {
        artcl : { data: artcl, set: setArtcl},
        review : { data: review, set: setReview},
    }
}