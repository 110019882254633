import { useState } from "react"

export const HomeState = () => {
	const [tour, setTour] = useState(false)
	const [welocmeTour, setWelocmeTour] = useState(false);
	const [la, setLa] = useState({
		list:[]
	})

	return {
		la: {data: la, set: setLa},
		tour: {data: tour, set: setTour},
		welocmeTour: {data: welocmeTour, set: setWelocmeTour}
	}
}
