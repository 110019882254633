import { useContext } from 'react';
import { ArrowLeftIcons } from '../../../../../core/global/Icons';
import { Box, IconButton, InputBase } from '@mui/material';
import { ToolContext } from '../../../../../core/context/ToolContext';
import { useHistory } from 'react-router-dom'
import dp from '../../../../../assets/images/logos/ptx_psl_logo.png';
import moment from 'moment/moment';

export const Result = () => {
    const { search_state } = useContext(ToolContext);
    const { srch } = search_state;

    const history = useHistory()

    const searchHandler = (val) => {
        history.push(`/psl/search`)
        srch.set({...srch.data, isOpen: val === 'search' ? true : false, value: val === 'search' ? srch.data.value : ''})
    }

    const gotoHandler = (id) => {
        history.push(`/psl/article?id=${id}`)
    }

    const discription = (title) => {
        if (title.length <= 60 ) {
            return <span>{title}</span>;
          } else {
            const shortenedText = title.substring(0, 60) + '...';
            return <span>{shortenedText}</span>;
          }
    }

    return (
        <Box height="100vh" width="100vw" bgcolor="#FFFFFF" display="flex" justifyContent="center" alignItems="center" flexDirection="column" overflow="hidden">
            <Box height="10%" width="100%" display="flex" alignItems="center" justifyContent="center" gap="8px" p="16px">
                <IconButton onClick={() => searchHandler('back')}><ArrowLeftIcons width={28} height={28} /></IconButton>
                <Box bgcolor="#F7F7F8" borderRadius="6px" p="8px 10px" width="100%" >
                    <InputBase
                        onFocus={() => searchHandler('search')}
                        defaultValue={srch.data.value}
                        sx={{width:'100%'}}
                        placeholder="Search"
                        inputProps={{ 'aria-label': 'search google maps' }}
                    />
                </Box>
            </Box>
            <Box height="100%" width="100%" p="16px" overflow="auto" className="noScrollcss">
                {
                    srch.data.result.length > 0 ? 
                        <Box>
                            {
                                srch.data.result.map((v, j) => (
                                    <Box key={j} display="flex" alignItems="center" justifyContent="space-between" width="100%" mb="24px" onClick={() => gotoHandler(v.id)}>
                                        <Box display="flex" flexDirection="column" width="60%" gap="8px">
                                            <Box display="flex" alignItems="center" gap="8px">
                                                <img src={dp} alt={dp} style={{height:'20px', width:'20px', objectFit:'cover', borderRadius:'100%', border:'2px solid #F1F1F1'}}/>
                                                <Box fontSize="12px" color="#283745">Philtaxes</Box>
                                            </Box>
                                            <Box fontSize="14px" fontWeight="600" color="#283745" lineHeight="23px">{discription(v.title)}</Box>
                                            <Box display="flex" alignItems="center" gap="8px" color="#495F73" fontSize="10px">
                                                {v.articlecategory} &nbsp; • &nbsp; {v.articletax} • &nbsp; {moment(v.publish_date).format('ll')}
                                            </Box>
                                        </Box>      
                                        <Box width="32%" display="flex" justifyContent="center">
                                            <img src={v.file_link} alt="img" style={{height:'80px', width:'100%', objectFit:'cover', borderRadius:'6px'}}/>
                                        </Box>
                                    </Box>
                                ))
                            }
                            <Box width="100%" display={srch.data.result.length >= 10 ? "flex" : "none"} justifyContent="center" py="24px" fontSize="12px" color="#9caed3">end of result</Box>
                        </Box>
                    :
                        <Box height="100%" width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="8px">
                            <Box color="#292C2F" fontSize="14px" fontWeight="600">Sorry, we didn't find any result!</Box>
                            <Box color="#8F969D" fontSize="12px">Try another term.</Box>
                        </Box>
                }
            </Box>
        </Box>
    );
};
