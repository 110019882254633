import { Box, Button } from "@mui/material"
import { useState } from "react";

const TestComponent = () => {
    const [message, setMessage] = useState("");

    const handleClick = () => {
        setMessage("Single Click Detected!");
      };
    
      const handleDoubleClick = () => {
        setMessage("Double Click Detected!");
      };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="100vh" bgcolor="#fff">
            <Box my="32px">{message}</Box>
            <Button onClick={handleClick} onDoubleClick={handleDoubleClick} variant="contained" >Click me!</Button>
        </Box>  
    )
}

export default TestComponent