import { ToolStates } from './ToolStates';
import { NavigationStates } from './NavigationStates';
import { SwitchingState } from './SwitchingState';
import { ToolCategoryState } from './ToolCategoryState';
import { NotifState } from './NotifState';
import { LibraryState } from './LibraryState';
import { HomeState } from './HomeState';
import { SearchState } from './SearchState';
import { ProfileState } from './ProfileState';

export const Context = () => {
    return {
        tool_state: ToolStates(),
        navigation_state: NavigationStates(),
        switching_state: SwitchingState(),
        tool_category_state: ToolCategoryState(),
        notif_state: NotifState(),
        library_state: LibraryState(),
        home_state: HomeState(),
        search_state: SearchState(),
        profile_state: ProfileState()
    }
}