import { Box, IconButton } from '@mui/material'
import { ArrowLeftIcons } from '../../../../../core/global/Icons'

export const Recent = () => {
    return (
        <Box height="100vh" width="100vw" bgcolor="#FFFFFF" display="flex" flexDirection="column" overflow="auto" p="16px">
            <Box width="100%" display="flex" alignItems="center">
                <Box width="70%">
                    <IconButton onClick={() => window.history.go(-1)}><ArrowLeftIcons width={32} height={32} /></IconButton>
                </Box>
                <Box width="100%">
                    <Box fontSize="16px" fontWeight="600">Recent</Box>
                </Box>
            </Box>
        </Box>
    )
}
