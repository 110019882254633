import { useState } from 'react'

export const SearchState = () => {
    const [srch, setSrch] = useState({
        value:'',
        sLoader: false,
        risLoad: false,
        isOpen: false,
        recent: [],
        suggest: [],
        result: []
    })

  return {
      srch : { data: srch, set: setSrch}
  }
}
