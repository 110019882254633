import { useState } from "react"

export const LibraryState = () => {
    const [artcl, setArtcl] = useState({
        list: [],
        view: [],
        suggestion: [],
        related: [],
        isLoad: false
    });

    const [categories, setCategories] = useState(
        { name: 'All', value:'all' }, 
        { name: 'BIR Issuance', value: 1 }, 
        { name: 'CTA Decision', value: 2 }
    )

    const [comment, setComment] = useState({
        list:[],
        toggled: false,
        type:'create',
        id: '',
        value:'',
        load: false,
        userInfo: {
            firstName:'',
            lastName:'',
            middleName:'',
            suffix:'',
            profile: null,
            cover: null
        }
    })

    const [reaction, setReaction] = useState({
        list:[],
        active:[]
    });

    const [count, setcount] = useState({comment: '', reaction: ''});

    return {
        artcl : { data: artcl, set: setArtcl},
        categories : { data: categories, set: setCategories},
        comment : { data: comment, set: setComment},
        reaction : { data: reaction, set: setReaction},
        count : { data: count, set: setcount},
    }
}