import { useState } from "react"

export const ProfileState = () => {
    const [pinfo, setPinfo] = useState({
        firstName:'',
        lastName:'',
        middleName:'',
        suffix:'',
        otherInfo: {
            gender:'',
            birthdate:'',
            civilStatus:''
        },
        profile:{
            cover: null,
            profile: null
        }
    })
    return {
        pinfo : { data: pinfo , set: setPinfo}
    }
}