import { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { VM } from '../VM';

import { Index as ToolCategories } from '../../__business/components/toolcategories/Index';
import { RenderAPI } from '../../__business/components/global/RenderAPI';
import { Switching } from '../../__business/components/swtiching/Index'
import { Settings } from '../../__business/components/settings/Index';
import {  ArticleForm } from '../../__business/components/library/article/articleForm/Index'

let view = VM();
const Navigation = lazy(() => import(`../../__business/components/layouts/navigations${view}/Index`))
const Home = lazy(() => import (`../../__business/components/home${view}/Index`))
const Library = lazy(() => import (`../../__business/components/library${view}/Index`))
const Report = lazy(() => import (`../../__business/components/report${view}/Index`))

export const WebRoute = () => {
    const location = useLocation().pathname;

    return (
        <Switch>
            {/* HOME */}
            <Route exact path="/biz" render={props=>(Render(Home, props, location))}/>
            <Route exact path="/biz/library" render={props=>(Render(Library, props, location))}/>
            <Route exact path="/biz/report" render={props=>(Render(Report, props, location))}/>
            <Route exact path="/biz/switching" render={props=><Switching/>} />
            <Route exact path="/biz/more" render={props=>(Render(Settings, props, location))} />
            <Route exact path="/biz/tool-categories/:params" render={props=>(Render(ToolCategories, props, location))} />
            <Route exact path="/biz/library/form" render={props=>(Render(ArticleForm, props, location))} />

            {/* 404 NOT FOUND */}
            <Route>
                <Container>
                    <Box display="flex">
                        Error 404
                    </Box>
                </Container>
            </Route>
        </Switch>
    )
}

const Render = (Component, props, location) => (
    <>
        {view === '' ? (
            <Box width="100%" height="100%">
                <Box width="100%" height="100%" display="flex">
                    <Box height="100%">
                        {
                            (location !== '/biz/profiling') &&
                            (location !==  '/biz/library/form') &&
                            (<Navigation side={1} />) 
                        }
                    </Box>
                    <Box display="flex" flexDirection="column" width="100%" height="100%">
                        {
                            (location !==  '/biz/library/form') &&
                            <Navigation side={0} />
                        }
                        <Box height="100%" className="overflowY noScrollcss">
                            <Component {...props} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        ) : (
            <Box width="100%" height="100%">
                <Box width="100%" height="100%" display="flex" flexDirection="column">
                    {(location !== '/biz/profiling' && location !== '/biz/more') && (
                        <Box minHeight={location === '/biz' ? '92px' : '40px'}>
                            <Navigation side={0} />
                        </Box>
                    )}
                    <Box height="100%" width="100%" className="overflowY noScrollcss"><Component {...props} /></Box>
                    {(location !== '/profile' && location !== '/biz/profiling') &&
                        <Box minHeight="54px">
                            <Navigation side={2} />
                        </Box>
                    } 
                </Box>
            </Box>
        )}
        <RenderAPI/>
    </>
)