import BLogo from '../../assets/images/logos/ptx_biz_logo.png'
import PLogo from '../../assets/images/logos/ptx_psl_logo.png'
// import ArrowRightIcon from '../../assets/images/website-logos/vuesax-linear-arrow-right.svg'

export const BizLogo = ({ size, br }) => {
    return (
        <img src={BLogo} height={size} width={size} alt="Pofsis" style={{ borderRadius: br }} />
    )
}

export const PslLogo = ({ size, br }) => {
    return (
        <img src={PLogo} height={size} width={size} alt="Pofsis" style={{ borderRadius: br }} />
    )
}

export const DownloadIcons = ({ fill = "none", stroke = "#85a195"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="vuesax_linear_receive-square" data-name="vuesax/linear/receive-square" transform="translate(-748 -380)">
                <g id="receive-square">
                    <path id="Vector" d="M7,20h6c5,0,7-2,7-7V7c0-5-2-7-7-7H7C2,0,0,2,0,7v6C0,18,2,20,7,20Z" transform="translate(750 382)" fill={fill} stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    <g id="Group">
                        <g id="Group-2" data-name="Group">
                            <path id="Vector-2" data-name="Vector" d="M0,0,3,3,6,0" transform="translate(757 391.51)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                            <path id="Vector-3" data-name="Vector" d="M0,8V0" transform="translate(760 386.51)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                        </g>
                        <path id="Vector-4" data-name="Vector" d="M0,0A18.949,18.949,0,0,0,12,0" transform="translate(754 396.51)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                    </g>
                    <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(748 380)" fill={fill} opacity="0" />
                </g>
            </g>
        </svg>
    )
}

export const RenewIcons = ({ fill = "none", stroke = "#fff", width = "24", height = "24" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="programming-arrows" transform="translate(-236 -252)">
                <path id="Vector" d="M7.5,11.5V2a2.006,2.006,0,0,0-2-2H0" transform="translate(247.5 256.5)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Vector-2" data-name="Vector" d="M3,0,0,2.5,3,5" transform="translate(247 254)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Vector-3" data-name="Vector" d="M6,3A3,3,0,1,1,3,0,3,3,0,0,1,6,3Z" transform="translate(252 268)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Vector-4" data-name="Vector" d="M0,0V9.5a2.006,2.006,0,0,0,2,2H7.5" transform="translate(241 260)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Vector-5" data-name="Vector" d="M0,5,3,2.5,0,0" transform="translate(246 269)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Vector-6" data-name="Vector" d="M6,3A3,3,0,1,1,3,0,3,3,0,0,1,6,3Z" transform="translate(238 254)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Vector-7" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(236 252)" fill={fill} opacity="0" />
            </g>
        </svg>
    )
}

export const WaveIcons = ({ fill = "none", stroke = "#fff", width = "24", height = "24" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path id="Path_71085" data-name="Path 71085" d="M551.11,546.59a4.788,4.788,0,0,1-2.562-.72,20.118,20.118,0,0,1-2.466-1.875l-.41-.346c-.39-.327-.787-.657-1.172-.976-1.361-1.13-2.767-2.3-4.089-3.513a3.411,3.411,0,0,1-.645-.758c-.471-.781-.455-1.089.082-1.572a1.075,1.075,0,0,1,.7-.2,1.837,1.837,0,0,1,.953.257,25.267,25.267,0,0,1,2.065,1.672c.63.54,1.224,1.05,1.646,1.367a.21.21,0,0,0,.127.043h0a.21.21,0,0,0,.185-.112.18.18,0,0,0-.024-.2c-.635-.768-2.2-2.292-2.941-2.99-.341-.323-.691-.649-1.029-.965-.614-.573-1.248-1.166-1.86-1.758-.467-.452-.946-.993-.707-1.682a1.117,1.117,0,0,1,1.12-.8,2.033,2.033,0,0,1,1.216.453,20.549,20.549,0,0,1,1.931,1.8c.226.23.46.468.694.7.143.14.327.327.541.543a26.392,26.392,0,0,0,2.186,2.065.229.229,0,0,0,.139.047.233.233,0,0,0,.2-.113.2.2,0,0,0-.009-.216,6.921,6.921,0,0,0-.795-.913c-.151-.154-.293-.3-.4-.43-.476-.559-.988-1.1-1.483-1.629-.44-.467-.894-.95-1.321-1.441-.184-.211-.448-.48-.753-.791a10.679,10.679,0,0,1-1.889-2.261,1.079,1.079,0,0,1,.409-1.377,1.357,1.357,0,0,1,.761-.241,1.6,1.6,0,0,1,.88.289,5.875,5.875,0,0,1,1.171,1.12c.114.131.231.266.351.4,1.236,1.344,3.707,3.986,4.649,4.977a.18.18,0,0,0,.132.057.188.188,0,0,0,.159-.089.158.158,0,0,0-.005-.169c-.45-.694-1.529-2.11-3.121-4.094l-.218-.271a14.313,14.313,0,0,1-1.25-1.7.9.9,0,0,1,0-.845,1.34,1.34,0,0,1,.975-.726,1.234,1.234,0,0,1,.208-.018,1.323,1.323,0,0,1,.73.231,11.427,11.427,0,0,1,1.606,1.74c.432.521.878,1.06,1.291,1.5.236.251.777.918,1.35,1.624.744.917,1.587,1.956,1.961,2.33a1.307,1.307,0,0,0,1.435.38,1.1,1.1,0,0,0,.791-1.129,3.633,3.633,0,0,1,.994-2.695,3.254,3.254,0,0,1,2.052-.705,1.539,1.539,0,0,1,.69.132.938.938,0,0,1,.427,1.359c-.084.164-.172.328-.257.488a6.924,6.924,0,0,0-.958,2.593,6.41,6.41,0,0,0,.1,1.56c.027.183.055.372.077.559.047.412.117.829.185,1.233a9.56,9.56,0,0,1,.187,2.953v.007a5.523,5.523,0,0,1-1.9,3.663,8.574,8.574,0,0,1-3.418,1.943A6.493,6.493,0,0,1,551.11,546.59Z" transform="translate(-538.899 -526.59)" fill={fill} stroke={stroke} />
        </svg>
    )
}

export const HomeIcons = ({fill, stroke="#FFFFFF"}) => {
    return (
        <svg id="vuesax_linear_gallery" data-name="vuesax/linear/gallery" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
            <g id="gallery" transform="translate(-364 -252)">
                <g id="vuesax_linear_home-2" data-name="vuesax/linear/home-2" transform="translate(-256 64)">
                <g id="home-2">
                    <path id="Vector" d="M7.02.823l-5.39,4.2A4.759,4.759,0,0,0,0,8.343v7.41a4.225,4.225,0,0,0,4.21,4.22H15.79A4.223,4.223,0,0,0,20,15.763V8.483a4.723,4.723,0,0,0-1.8-3.45L12.02.7A4.487,4.487,0,0,0,7.02.823Z" transform="translate(622 190.017)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Vector-2" data-name="Vector" d="M0,3V0" transform="translate(632 202.99)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                    <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(620 188)" fill={fill} opacity="0"/>
                </g>
                </g>
                <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(364 252)" fill={fill} opacity="0"/>
            </g>
        </svg>
    )
}

export const ArrowRightIcons = ({ fill = "#1d3a5f", stroke = "#fff", width = "24", height = "24" }) => {
    return (
        <svg id="vuesax_linear_arrow-right" data-name="vuesax/linear/arrow-right" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="arrow-right">
                <path id="Vector" d="M0,0,6.07,6.07,0,12.14" transform="translate(14.43 5.93)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Vector-2" data-name="Vector" d="M0,0H16.83" transform="translate(3.5 12)" fill="none" stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(24 24) rotate(180)" fill="none" opacity="0" />
            </g>
        </svg>

    )
}

export const ArrowLeftIcons = ({ fill = "none", stroke = "#fff", width, height}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 12H19" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 12L9 16" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 12L9 8" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const NodataIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_29380" data-name="Rectangle 29380" width="80" height="80" transform="translate(2906 -1182)" fill="#fff" />
                </clipPath>
            </defs>
            <g id="Mask_Group_2630" data-name="Mask Group 2630" transform="translate(-2906 1182)" clipPath="url(#clip-path)">
                <g id="Group_53344" data-name="Group 53344">
                    <path id="Path_109168" data-name="Path 109168" d="M29.07,0A29.07,29.07,0,1,1,0,29.07,29.07,29.07,0,0,1,29.07,0Z" transform="translate(2917 -1171)" fill="#f6f6f6" />
                    <path id="Path_109169" data-name="Path 109169" d="M3.735,0A3.735,3.735,0,1,1,0,3.735,3.735,3.735,0,0,1,3.735,0Z" transform="translate(2912.5 -1165.174)" fill="#c0c2c2" />
                    <path id="Path_109170" data-name="Path 109170" d="M2.948,0A2.948,2.948,0,1,1,0,2.948,2.948,2.948,0,0,1,2.948,0Z" transform="translate(2917.088 -1171.07)" fill="#c0c2c2" />
                    <circle id="Ellipse_4274" data-name="Ellipse 4274" cx="3" cy="3" r="3" transform="translate(2966.5 -1119.07)" fill="#c0c2c2" />
                    <circle id="Ellipse_4275" data-name="Ellipse 4275" cx="4" cy="4" r="4" transform="translate(2971.5 -1127.07)" fill="#c0c2c2" />
                    <g id="vuesax_bulk_transaction-minus" data-name="vuesax/bulk/transaction-minus" transform="translate(2626 -1414)">
                        <g id="transaction-minus" transform="translate(300 252)">
                            <path id="Vector" d="M0,0H40.154V40.154H0Z" fill="none" opacity="0" />
                            <path id="Vector-2" data-name="Vector" d="M5.568,30a3.045,3.045,0,0,1,4.794.254L12.1,32.546a3.01,3.01,0,0,0,5.035,0l1.736-2.288A3.045,3.045,0,0,1,23.663,30c3.059,3.221,5.551,2.153,5.551-2.356V8.543C29.213,1.712,27.6,0,21.1,0H8.111C1.615,0,0,1.712,0,8.543V27.63C.017,32.156,2.526,33.207,5.568,30Z" transform="translate(5.455 3.126)" fill="#e1e2e2" />
                            <g id="Group" transform="translate(14.607 15.544)">
                                <path id="Vector-3" data-name="Vector" d="M9.769,2.345h-8.6A1.181,1.181,0,0,1,0,1.172,1.181,1.181,0,0,1,1.172,0h8.6a1.181,1.181,0,0,1,1.172,1.172A1.181,1.181,0,0,1,9.769,2.345Z" fill="#127a3e" />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const MActionIcons = ({ fill }) => {
    return (
        <svg width="68" height="68" viewBox="0 0 68 68" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M33.9994 17.3334C33.2216 17.3334 32.5688 17.5973 32.041 18.1251C31.5132 18.6529 31.2494 19.3056 31.2494 20.0834C31.2494 20.8612 31.5132 21.514 32.041 22.0418C32.5688 22.5695 33.2216 22.8334 33.9994 22.8334C34.7771 22.8334 35.4299 22.5695 35.9577 22.0418C36.4855 21.514 36.7494 20.8612 36.7494 20.0834C36.7494 19.3056 36.4855 18.6529 35.9577 18.1251C35.4299 17.5973 34.7771 17.3334 33.9994 17.3334ZM36.2494 30.0834H31.2494V51.1668H36.2494V30.0834ZM33.9994 0.666748C38.5549 0.666748 42.8605 1.54175 46.916 3.29175C50.9716 5.04175 54.5132 7.43064 57.541 10.4584C60.5688 13.4862 62.9577 17.0279 64.7077 21.0834C66.4577 25.139 67.3327 29.4723 67.3327 34.0834C67.3327 38.639 66.4577 42.9445 64.7077 47.0001C62.9577 51.0556 60.5688 54.5834 57.541 57.5834C54.5132 60.5834 50.9716 62.9584 46.916 64.7084C42.8605 66.4584 38.5271 67.3334 33.916 67.3334C29.3605 67.3334 25.0549 66.4584 20.9994 64.7084C16.9438 62.9584 13.416 60.5834 10.416 57.5834C7.41602 54.5834 5.04102 51.0556 3.29102 47.0001C1.54102 42.9445 0.666016 38.6112 0.666016 34.0001C0.666016 29.4445 1.54102 25.139 3.29102 21.0834C5.04102 17.0279 7.41602 13.4862 10.416 10.4584C13.416 7.43064 16.9438 5.04175 20.9994 3.29175C25.0549 1.54175 29.3882 0.666748 33.9994 0.666748Z" fill={fill} />
        </svg>
    )
}

export const SuccessIcons = ({ fill }) => {
    return (
        <svg id="vuesax_bold_tick-circle" data-name="vuesax/bold/tick-circle" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="tick-circle">
                <path id="Vector" d="M10,0A10,10,0,1,0,20,10,10.016,10.016,0,0,0,10,0Zm4.78,7.7L9.11,13.37a.748.748,0,0,1-1.06,0L5.22,10.54A.75.75,0,1,1,6.28,9.48l2.3,2.3,5.14-5.14A.75.75,0,0,1,14.78,7.7Z" transform="translate(2 2)" fill={fill} />
                <path id="Vector-2" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(24 24) rotate(180)" fill="none" opacity="0" />
            </g>
        </svg>
    )
}

export const ChatIcons = ({fill="none", stroke="#292D32", width="24", height="24"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 10.5H15.5" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 18.43H11L15.45 21.39C16.11 21.83 17 21.36 17 20.56V18.43C20 18.43 22 16.43 22 13.43V7.42999C22 4.42999 20 2.42999 17 2.42999H7C4 2.42999 2 4.42999 2 7.42999V13.43C2 16.43 4 18.43 7 18.43Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const NotificationIcons = ({fill = '#283745', width="24", height="24"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.99998 1C8.99998 0.734783 8.89462 0.48043 8.70709 0.292893C8.51955 0.105357 8.2652 0 7.99998 0C7.73476 0 7.48041 0.105357 7.29287 0.292893C7.10534 0.48043 6.99998 0.734783 6.99998 1V1.75H6.44298C5.37101 1.74993 4.33931 2.15839 3.5579 2.89224C2.7765 3.62608 2.30414 4.63014 2.23698 5.7L2.01598 9.234C1.93171 10.5814 1.47928 11.8799 0.707981 12.988C0.548614 13.2171 0.451238 13.4835 0.425324 13.7613C0.399409 14.0392 0.445837 14.319 0.560093 14.5736C0.67435 14.8281 0.852545 15.0488 1.07735 15.2141C1.30215 15.3795 1.56591 15.4838 1.84298 15.517L5.24998 15.925V17C5.24998 17.7293 5.53971 18.4288 6.05544 18.9445C6.57116 19.4603 7.27064 19.75 7.99998 19.75C8.72933 19.75 9.4288 19.4603 9.94452 18.9445C10.4602 18.4288 10.75 17.7293 10.75 17V15.925L14.157 15.516C14.4338 15.4826 14.6973 15.3781 14.9218 15.2128C15.1463 15.0475 15.3243 14.8269 15.4384 14.5725C15.5525 14.3181 15.5989 14.0385 15.5731 13.7608C15.5473 13.4832 15.4501 13.217 15.291 12.988C14.5201 11.8798 14.068 10.5813 13.984 9.234L13.763 5.701C13.6961 4.63096 13.2238 3.62665 12.4424 2.8926C11.661 2.15855 10.6291 1.74995 9.55698 1.75H8.99998V1ZM6.74998 17C6.74998 17.3315 6.88168 17.6495 7.1161 17.8839C7.35052 18.1183 7.66846 18.25 7.99998 18.25C8.3315 18.25 8.64944 18.1183 8.88386 17.8839C9.11829 17.6495 9.24998 17.3315 9.24998 17V16.25H6.74998V17Z" fill={fill}/>
        </svg>
    )
}

export const TcIcons = ({fill="none", stroke="#89A594"}) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M18.3333 7.09996V3.31663C18.3333 2.14163 17.8 1.66663 16.475 1.66663H13.1083C11.7833 1.66663 11.25 2.14163 11.25 3.31663V7.09163C11.25 8.27496 11.7833 8.74163 13.1083 8.74163H16.475C17.8 8.74996 18.3333 8.27496 18.3333 7.09996Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.3333 16.475V13.1083C18.3333 11.7833 17.8 11.25 16.475 11.25H13.1083C11.7833 11.25 11.25 11.7833 11.25 13.1083V16.475C11.25 17.8 11.7833 18.3333 13.1083 18.3333H16.475C17.8 18.3333 18.3333 17.8 18.3333 16.475Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.75008 7.09996V3.31663C8.75008 2.14163 8.21675 1.66663 6.89175 1.66663H3.52508C2.20008 1.66663 1.66675 2.14163 1.66675 3.31663V7.09163C1.66675 8.27496 2.20008 8.74163 3.52508 8.74163H6.89175C8.21675 8.74996 8.75008 8.27496 8.75008 7.09996Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.75008 16.475V13.1083C8.75008 11.7833 8.21675 11.25 6.89175 11.25H3.52508C2.20008 11.25 1.66675 11.7833 1.66675 13.1083V16.475C1.66675 17.8 2.20008 18.3333 3.52508 18.3333H6.89175C8.21675 18.3333 8.75008 17.8 8.75008 16.475Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const MyToolIcons = ({fill = '#283745', stroke="none", width="24", height="24"}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.20605 2.63109C5.40484 2.54224 4.59626 2.54224 3.79505 2.63109C3.50177 2.66367 3.2281 2.7944 3.01844 3.00206C2.80878 3.20972 2.67544 3.48213 2.64005 3.77509C2.54495 4.58832 2.54495 5.40986 2.64005 6.22309C2.71005 6.82909 3.19605 7.30009 3.79505 7.36709C4.58905 7.45709 5.41105 7.45709 6.20505 7.36709C6.49834 7.3345 6.77201 7.20377 6.98166 6.99611C7.19132 6.78845 7.32466 6.51605 7.36005 6.22309C7.45513 5.40986 7.45513 4.58832 7.36005 3.77509C7.3247 3.48228 7.19151 3.20999 6.98205 3.00235C6.7726 2.79471 6.49916 2.66388 6.20605 2.63109Z" fill={fill} stroke={stroke}/>
            <path d="M13.2061 2.63114C12.4045 2.54222 11.5956 2.54222 10.7941 2.63114C10.5009 2.66394 10.2275 2.79476 10.018 3.0024C9.80859 3.21004 9.6754 3.48233 9.64005 3.77514C9.54495 4.58837 9.54495 5.40991 9.64005 6.22314C9.71005 6.82914 10.1961 7.30014 10.7941 7.36714C11.5891 7.45714 12.4111 7.45714 13.2061 7.36714C13.4992 7.33434 13.7726 7.20352 13.9821 6.99588C14.1915 6.78824 14.3247 6.51595 14.3601 6.22314C14.455 5.40991 14.455 4.58838 14.3601 3.77514C14.3247 3.48233 14.1915 3.21004 13.9821 3.0024C13.7726 2.79476 13.4992 2.66394 13.2061 2.63114Z" fill={fill} stroke={stroke}/>
            <path d="M20.206 2.63114C19.4045 2.54222 18.5956 2.54222 17.794 2.63114C17.5009 2.66394 17.2275 2.79476 17.018 3.0024C16.8086 3.21004 16.6754 3.48233 16.64 3.77514C16.545 4.58837 16.545 5.40991 16.64 6.22314C16.71 6.82914 17.196 7.30014 17.794 7.36714C18.59 7.45714 19.411 7.45714 20.206 7.36714C20.4991 7.33434 20.7726 7.20352 20.982 6.99588C21.1915 6.78824 21.3247 6.51595 21.36 6.22314C21.455 5.40991 21.455 4.58838 21.36 3.77514C21.3247 3.48233 21.1915 3.21004 20.982 3.0024C20.7726 2.79476 20.4991 2.66394 20.206 2.63114Z" fill={fill} stroke={stroke}/>
            <path d="M6.20606 9.63109C5.40485 9.54224 4.59627 9.54224 3.79506 9.63109C3.50177 9.66367 3.2281 9.7944 3.01845 10.0021C2.80879 10.2097 2.67545 10.4821 2.64006 10.7751C2.54495 11.5883 2.54495 12.4099 2.64006 13.2231C2.71006 13.8291 3.19606 14.3001 3.79506 14.3671C4.58906 14.4571 5.41106 14.4571 6.20506 14.3671C6.49834 14.3345 6.77201 14.2038 6.98167 13.9961C7.19133 13.7885 7.32467 13.516 7.36006 13.2231C7.45505 12.4099 7.45505 11.5883 7.36006 10.7751C7.32471 10.4823 7.19152 10.21 6.98206 10.0023C6.7726 9.79471 6.49916 9.66388 6.20606 9.63109Z" fill={fill} stroke={stroke}/>
            <path d="M13.2061 9.63114C12.4045 9.54222 11.5956 9.54222 10.7941 9.63114C10.501 9.66394 10.2275 9.79476 10.0181 10.0024C9.8086 10.21 9.67541 10.4823 9.64006 10.7751C9.54495 11.5884 9.54495 12.4099 9.64006 13.2231C9.71006 13.8291 10.1961 14.3001 10.7941 14.3671C11.5891 14.4571 12.4111 14.4571 13.2061 14.3671C13.4992 14.3343 13.7726 14.2035 13.9821 13.9959C14.1915 13.7882 14.3247 13.516 14.3601 13.2231C14.4551 12.4099 14.4551 11.5884 14.3601 10.7751C14.3247 10.4823 14.1915 10.21 13.9821 10.0024C13.7726 9.79476 13.4992 9.66394 13.2061 9.63114Z" fill={fill} stroke={stroke}/>
            <path d="M20.206 9.63114C19.4045 9.54222 18.5956 9.54222 17.794 9.63114C17.5009 9.66394 17.2275 9.79476 17.018 10.0024C16.8086 10.21 16.6754 10.4823 16.64 10.7751C16.545 11.5884 16.545 12.4099 16.64 13.2231C16.71 13.8291 17.196 14.3001 17.794 14.3671C18.59 14.4571 19.411 14.4571 20.206 14.3671C20.4991 14.3343 20.7726 14.2035 20.982 13.9959C21.1915 13.7882 21.3247 13.516 21.36 13.2231C21.4551 12.4099 21.4551 11.5884 21.36 10.7751C21.3247 10.4823 21.1915 10.21 20.982 10.0024C20.7726 9.79476 20.4991 9.66394 20.206 9.63114Z" fill={fill} stroke={stroke}/>
            <path d="M6.20606 16.6311C5.40485 16.5422 4.59627 16.5422 3.79506 16.6311C3.50177 16.6637 3.2281 16.7944 3.01845 17.0021C2.80879 17.2098 2.67545 17.4822 2.64006 17.7751C2.54495 18.5884 2.54495 19.4099 2.64006 20.2231C2.71006 20.8291 3.19606 21.3001 3.79506 21.3671C4.58906 21.4571 5.41106 21.4571 6.20506 21.3671C6.49834 21.3345 6.77201 21.2038 6.98167 20.9961C7.19133 20.7885 7.32467 20.5161 7.36006 20.2231C7.45505 19.4099 7.45505 18.5884 7.36006 17.7751C7.32471 17.4823 7.19152 17.21 6.98206 17.0024C6.7726 16.7947 6.49916 16.6639 6.20606 16.6311Z" fill={fill} stroke={stroke}/>
            <path d="M13.2061 16.6312C12.4045 16.5422 11.5956 16.5422 10.7941 16.6312C10.501 16.664 10.2275 16.7948 10.0181 17.0024C9.8086 17.2101 9.67541 17.4824 9.64006 17.7752C9.54495 18.5884 9.54495 19.41 9.64006 20.2232C9.71006 20.8292 10.1961 21.3002 10.7941 21.3672C11.5891 21.4572 12.4111 21.4572 13.2061 21.3672C13.4992 21.3344 13.7726 21.2036 13.9821 20.9959C14.1915 20.7883 14.3247 20.516 14.3601 20.2232C14.4551 19.4099 14.4551 18.5884 14.3601 17.7752C14.3247 17.4824 14.1915 17.2101 13.9821 17.0024C13.7726 16.7948 13.4992 16.664 13.2061 16.6312Z" fill={fill} stroke={stroke}/>
            <path d="M20.206 16.6312C19.4045 16.5422 18.5956 16.5422 17.794 16.6312C17.5009 16.664 17.2275 16.7948 17.018 17.0024C16.8086 17.2101 16.6754 17.4824 16.64 17.7752C16.545 18.5884 16.545 19.4099 16.64 20.2232C16.71 20.8292 17.196 21.3002 17.794 21.3672C18.59 21.4572 19.411 21.4572 20.206 21.3672C20.4991 21.3344 20.7726 21.2036 20.982 20.9959C21.1915 20.7883 21.3247 20.516 21.36 20.2232C21.4551 19.4099 21.4551 18.5884 21.36 17.7752C21.3247 17.4824 21.1915 17.2101 20.982 17.0024C20.7726 16.7948 20.4991 16.664 20.206 16.6312Z" fill={fill} stroke={stroke}/>
        </svg>        
    )
}

export const ReferUserIcons = ({fill, stroke=""}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
            <g id="profile-2user" transform="translate(-172 -252)">
                <path id="Vector" d="M4.6,8.87a1.818,1.818,0,0,0-.33,0,4.445,4.445,0,1,1,.33,0Z" transform="translate(176.56 254)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-2" data-name="Vector" d="M.13,0a3.5,3.5,0,0,1,3.5,3.5A3.5,3.5,0,0,1,.26,7,1.129,1.129,0,0,0,0,7" transform="translate(188.28 256)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-3" data-name="Vector" d="M1.815,1.373c-2.42,1.62-2.42,4.26,0,5.87a9.766,9.766,0,0,0,10.01,0c2.42-1.62,2.42-4.26,0-5.87A9.812,9.812,0,0,0,1.815,1.373Z" transform="translate(174.345 265.188)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-4" data-name="Vector" d="M0,6a4.837,4.837,0,0,0,1.96-.87,2.533,2.533,0,0,0,0-4.27A4.973,4.973,0,0,0,.03,0" transform="translate(190.34 266)" fill={fill} stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-5" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(196 276) rotate(180)" fill={fill} opacity="0"/>
            </g>
        </svg>      
    )
}

export const SupportIcons = ({ fill = "#89A594", width="20", height="20"}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 2.125C6.29917 2.125 4.20333 3.81667 3.84583 5.875H5.66667C5.83243 5.875 5.9914 5.94085 6.10861 6.05806C6.22582 6.17527 6.29167 6.33424 6.29167 6.5V12.3333C6.29167 12.4991 6.22582 12.6581 6.10861 12.7753C5.9914 12.8925 5.83243 12.9583 5.66667 12.9583H3.16667C2.55888 12.9583 1.97598 12.7169 1.54621 12.2871C1.11644 11.8573 0.875 11.2745 0.875 10.6667V8.16667C0.874894 7.66259 1.04099 7.17254 1.34757 6.77241C1.65414 6.37228 2.08409 6.08439 2.57083 5.95333C2.88917 3.03333 5.72833 0.875 9 0.875C12.2717 0.875 15.1108 3.03333 15.4292 5.95333C15.9158 6.08452 16.3457 6.37244 16.6523 6.77254C16.9588 7.17265 17.125 7.66263 17.125 8.16667V10.6667C17.125 11.1722 16.9579 11.6636 16.6497 12.0644C16.3415 12.4651 15.9095 12.7528 15.4208 12.8825C15.2882 13.828 14.8184 14.6937 14.0979 15.3202C13.3774 15.9467 12.4548 16.2917 11.5 16.2917H10.5458C10.4008 16.6502 10.1356 16.9472 9.79559 17.1316C9.45562 17.3161 9.06209 17.3765 8.68243 17.3026C8.30277 17.2288 7.96063 17.0251 7.71464 16.7267C7.46865 16.4282 7.33413 16.0534 7.33413 15.6667C7.33413 15.2799 7.46865 14.9051 7.71464 14.6067C7.96063 14.3082 8.30277 14.1046 8.68243 14.0307C9.06209 13.9568 9.45562 14.0173 9.79559 14.2017C10.1356 14.3862 10.4008 14.6831 10.5458 15.0417H11.5C12.1097 15.0417 12.7015 14.836 13.1799 14.4579C13.6582 14.0799 13.995 13.5515 14.1358 12.9583H12.3333C12.1676 12.9583 12.0086 12.8925 11.8914 12.7753C11.7742 12.6581 11.7083 12.4991 11.7083 12.3333V6.5C11.7083 6.33424 11.7742 6.17527 11.8914 6.05806C12.0086 5.94085 12.1676 5.875 12.3333 5.875H14.1542C13.7967 3.81667 11.7008 2.125 9 2.125ZM3.16667 7.125C2.59167 7.125 2.125 7.59167 2.125 8.16667V10.6667C2.125 11.2417 2.59167 11.7083 3.16667 11.7083H5.04167V7.125H3.16667ZM15.875 8.16667C15.875 7.59167 15.4083 7.125 14.8333 7.125H12.9583V11.7083H14.8333C15.4083 11.7083 15.875 11.2417 15.875 10.6667V8.16667Z" fill={fill}/>
        </svg>
    )
}

export const LogoutIcons = ({ fill = "#89A594"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g id="vuesax_outline_logout" data-name="vuesax/outline/logout" transform="translate(-748 -444)"><g id="logout"><path id="Vector" d="M7.092,20.54h-.13c-4.44,0-6.58-1.75-6.95-5.67a.751.751,0,0,1,.68-.82.765.765,0,0,1,.82.68c.29,3.14,1.77,4.31,5.46,4.31H7.1c4.07,0,5.51-1.44,5.51-5.51V7.01c0-4.07-1.44-5.51-5.51-5.51h-.13c-3.71,0-5.19,1.19-5.46,4.39A.758.758,0,0,1,0,5.76C.342,1.78,2.492,0,6.962,0h.13C12,0,14.1,2.1,14.1,7.01v6.52C14.1,18.44,12,20.54,7.092,20.54Z" transform="translate(756.148 445.73)" fill={fill}></path><path id="Vector-2" data-name="Vector" d="M12.13,1.5H.75A.755.755,0,0,1,0,.75.755.755,0,0,1,.75,0H12.13a.755.755,0,0,1,.75.75A.755.755,0,0,1,12.13,1.5Z" transform="translate(750.87 455.25)" fill={fill}></path><path id="Vector-3" data-name="Vector" d="M4.1,8.2a.742.742,0,0,1-.53-.22L.218,4.628a.754.754,0,0,1,0-1.06L3.568.218a.75.75,0,0,1,1.06,1.06L1.807,4.1l2.82,2.82a.754.754,0,0,1,0,1.06A.725.725,0,0,1,4.1,8.2Z" transform="translate(749.753 451.902)" fill={fill}></path><path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(748 444)" fill="none" opacity="0"></path></g></g></svg>
    )
}


export const LessThanIcons = ({stroke = "#107038"}) => {
    return(
        <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 14L1 7.5L6 1" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const GreaterThanIcons = ({stroke="#107038"}) => {
    return(
        <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0.999999L6 7.5L1 14" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const NoToolsIcons = () => {
    return(
        <svg width="150" height="120" viewBox="0 0 150 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M124.2 39C126.52 39 128.4 40.8804 128.4 43.2C128.4 45.5196 126.52 47.4 124.2 47.4H100.2C102.52 47.4 104.4 49.2804 104.4 51.6C104.4 53.9196 102.52 55.8 100.2 55.8H113.4C115.72 55.8 117.6 57.6804 117.6 60C117.6 62.3196 115.72 64.2 113.4 64.2H107.296C104.371 64.2 102 66.0804 102 68.4C102 69.9464 103.2 71.3464 105.6 72.6C107.92 72.6 109.8 74.4804 109.8 76.8C109.8 79.1196 107.92 81 105.6 81H55.7999C53.4804 81 51.5999 79.1196 51.5999 76.8C51.5999 74.4804 53.4804 72.6 55.7999 72.6H32.4C30.0804 72.6 28.2 70.7196 28.2 68.4C28.2 66.0804 30.0804 64.2 32.4 64.2H56.4C58.7195 64.2 60.5999 62.3196 60.5999 60C60.5999 57.6804 58.7195 55.8 56.4 55.8H41.4C39.0804 55.8 37.2 53.9196 37.2 51.6C37.2 49.2804 39.0804 47.4 41.4 47.4H65.3999C63.0804 47.4 61.1999 45.5196 61.1999 43.2C61.1999 40.8804 63.0804 39 65.3999 39H124.2ZM124.2 55.8C126.52 55.8 128.4 57.6804 128.4 60C128.4 62.3196 126.52 64.2 124.2 64.2C121.88 64.2 120 62.3196 120 60C120 57.6804 121.88 55.8 124.2 55.8Z" fill="#E2EDE7"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M92.2031 38.4003L97.7842 79.1059L98.2856 83.1897C98.4472 84.5053 97.5116 85.7028 96.196 85.8643L61.0599 90.1785C59.7443 90.34 58.5468 89.4045 58.3853 88.0889L52.9758 44.0324C52.8951 43.3746 53.3628 42.7758 54.0206 42.6951C54.0248 42.6946 54.029 42.6941 54.0331 42.6936L56.9481 42.3666M59.3053 42.1021L62.0575 41.7934L59.3053 42.1021Z" fill="white"/>
            <path d="M93.4415 38.2305C93.3477 37.5466 92.7173 37.0681 92.0333 37.1619C91.3493 37.2557 90.8709 37.8862 90.9647 38.5701L93.4415 38.2305ZM97.7842 79.1059L99.0249 78.9536C99.0242 78.9478 99.0234 78.942 99.0226 78.9361L97.7842 79.1059ZM98.2856 83.1897L99.5263 83.0374L98.2856 83.1897ZM96.196 85.8643L96.3484 87.105L96.196 85.8643ZM61.0599 90.1785L61.2122 91.4192L61.0599 90.1785ZM58.3853 88.0889L59.626 87.9365L58.3853 88.0889ZM54.0331 42.6936L54.1725 43.9358L54.0331 42.6936ZM57.0875 43.6088C57.7735 43.5319 58.2673 42.9133 58.1903 42.2273C58.1134 41.5412 57.4948 41.0474 56.8088 41.1244L57.0875 43.6088ZM59.166 40.8599C58.4799 40.9369 57.9861 41.5554 58.0631 42.2414C58.1401 42.9275 58.7586 43.4213 59.4446 43.3443L59.166 40.8599ZM62.1969 43.0356C62.8829 42.9586 63.3767 42.3401 63.2997 41.654C63.2228 40.968 62.6042 40.4742 61.9182 40.5512L62.1969 43.0356ZM90.9647 38.5701L96.5458 79.2757L99.0226 78.9361L93.4415 38.2305L90.9647 38.5701ZM96.5435 79.2583L97.045 83.3421L99.5263 83.0374L99.0249 78.9536L96.5435 79.2583ZM97.045 83.3421C97.1224 83.9725 96.6741 84.5462 96.0437 84.6236L96.3484 87.105C98.3492 86.8593 99.772 85.0382 99.5263 83.0374L97.045 83.3421ZM96.0437 84.6236L60.9076 88.9378L61.2122 91.4192L96.3484 87.105L96.0437 84.6236ZM60.9076 88.9378C60.2772 89.0152 59.7034 88.5669 59.626 87.9365L57.1446 88.2412C57.3903 90.242 59.2114 91.6649 61.2122 91.4192L60.9076 88.9378ZM59.626 87.9365L54.2165 43.88L51.7352 44.1847L57.1446 88.2412L59.626 87.9365ZM54.2165 43.88C54.2199 43.9074 54.2004 43.9324 54.173 43.9357L53.8683 41.4544C52.5253 41.6193 51.5702 42.8417 51.7352 44.1847L54.2165 43.88ZM54.173 43.9357L54.1725 43.9358L53.8938 41.4514C53.8853 41.4523 53.8768 41.4533 53.8683 41.4544L54.173 43.9357ZM54.1725 43.9358L57.0875 43.6088L56.8088 41.1244L53.8938 41.4514L54.1725 43.9358ZM59.4446 43.3443L62.1969 43.0356L61.9182 40.5512L59.166 40.8599L59.4446 43.3443Z" fill="#107038"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M90.6841 40.9614L95.7359 77.8516L96.1903 81.5525C96.3367 82.7448 95.5003 83.8286 94.3221 83.9732L62.8569 87.8367C61.6788 87.9813 60.605 87.1321 60.4586 85.9398L55.6661 46.9079C55.5315 45.8116 56.3111 44.8137 57.4075 44.6791L60.506 44.2987" fill="#F6F7F8"/>
            <path d="M65.2532 34C65.2532 32.4812 66.4844 31.25 68.0032 31.25H93.0748C93.8038 31.25 94.503 31.5395 95.0187 32.0549L102.147 39.1788C102.663 39.6946 102.953 40.3943 102.953 41.124V76.4C102.953 77.9188 101.722 79.15 100.203 79.15H68.0032C66.4844 79.15 65.2532 77.9188 65.2532 76.4V34Z" fill="white" stroke="#107038" strokeWidth="2.5"/>
            <path d="M94.0032 31.4419V38.4002C94.0032 39.3943 94.8091 40.2002 95.8032 40.2002H100.563" stroke="#107038" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M70.8 70.8002H86.4M70.8 40.2002H86.4H70.8ZM70.8 47.4002H96.6H70.8ZM70.8 55.2002H96.6H70.8ZM70.8 63.0002H96.6H70.8Z" stroke="#737373" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const LeftArrowTCIcons = ({stroke="#7B7F82", width="24", height="24"}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24">
            <g id="vuesax_linear_arrow-left" data-name="vuesax/linear/arrow-left" transform="translate(-684 -188)">
                <g id="arrow-left">
                <path id="Vector" d="M6.07,0,0,6.07l6.07,6.07" transform="translate(687.5 193.93)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-2" data-name="Vector" d="M16.83,0H0" transform="translate(687.67 200)" fill="none" stroke={stroke} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Vector-3" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(708 212) rotate(180)" fill="none" opacity="0"/>
                </g>
            </g>
        </svg>
    )
}

export const SearchIcons = ({size = '24', stroke = '#283745'}) => {
    return(
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 17C10.9193 17 11.8295 16.8189 12.6788 16.4672C13.5281 16.1154 14.2997 15.5998 14.9497 14.9497C15.5998 14.2997 16.1154 13.5281 16.4672 12.6788C16.8189 11.8295 17 10.9193 17 10C17 9.08075 16.8189 8.17049 16.4672 7.32122C16.1154 6.47194 15.5998 5.70026 14.9497 5.05025C14.2997 4.40024 13.5281 3.88463 12.6788 3.53284C11.8295 3.18106 10.9193 3 10 3C8.14348 3 6.36301 3.7375 5.05025 5.05025C3.7375 6.36301 3 8.14348 3 10C3 11.8565 3.7375 13.637 5.05025 14.9497C6.36301 16.2625 8.14348 17 10 17Z" stroke={stroke} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M21 21L15 15" stroke={stroke} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const LibraryIcon = ({fill = "#fff", stroke = '#283745'}) => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M6.66667 3.3335H5C4.77899 3.3335 4.56703 3.42129 4.41075 3.57757C4.25447 3.73385 4.16667 3.94582 4.16667 4.16683V15.8335C4.16667 16.0545 4.25447 16.2665 4.41075 16.4228C4.56703 16.579 4.77899 16.6668 5 16.6668H6.66667C6.88768 16.6668 7.09964 16.579 7.25592 16.4228C7.4122 16.2665 7.5 16.0545 7.5 15.8335V4.16683C7.5 3.94582 7.4122 3.73385 7.25592 3.57757C7.09964 3.42129 6.88768 3.3335 6.66667 3.3335Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 3.3335H8.33333C8.11232 3.3335 7.90036 3.42129 7.74408 3.57757C7.5878 3.73385 7.5 3.94582 7.5 4.16683V15.8335C7.5 16.0545 7.5878 16.2665 7.74408 16.4228C7.90036 16.579 8.11232 16.6668 8.33333 16.6668H10C10.221 16.6668 10.433 16.579 10.5893 16.4228C10.7455 16.2665 10.8333 16.0545 10.8333 15.8335V4.16683C10.8333 3.94582 10.7455 3.73385 10.5893 3.57757C10.433 3.42129 10.221 3.3335 10 3.3335Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.16667 6.6665H7.5" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.5 13.3335H10.8333" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.4792 3.31766L11.855 3.69266C11.7483 3.71722 11.6474 3.76258 11.5582 3.82615C11.469 3.88971 11.3933 3.97023 11.3352 4.06311C11.2772 4.15598 11.238 4.25939 11.2199 4.36741C11.2019 4.47544 11.2053 4.58596 11.23 4.69266L13.855 16.0593C13.8796 16.1661 13.9249 16.2669 13.9885 16.3561C14.052 16.4453 14.1326 16.5211 14.2254 16.5791C14.3183 16.6372 14.4217 16.6763 14.5297 16.6944C14.6378 16.7124 14.7483 16.709 14.855 16.6843L16.4783 16.3093C16.585 16.2848 16.6857 16.2394 16.7749 16.1759C16.864 16.1124 16.9397 16.032 16.9978 15.9392C17.0558 15.8464 17.095 15.7431 17.1131 15.6352C17.1312 15.5272 17.1279 15.4168 17.1033 15.3102L14.4783 3.94266C14.4538 3.83602 14.4084 3.73525 14.3449 3.64613C14.2814 3.557 14.201 3.48126 14.1082 3.42322C14.0154 3.36518 13.9121 3.32599 13.8042 3.30788C13.6963 3.28977 13.5858 3.29309 13.4792 3.31766Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11.7925 7.12939L15.0408 6.37939" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.2925 13.6265L16.5408 12.8765" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ReportIcon = ({fill = "none", stroke = '#283745'}) => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1667 17.5002C15.0507 17.5002 15.8986 17.149 16.5237 16.5239C17.1488 15.8987 17.5 15.0509 17.5 14.1668C17.5 13.2828 17.1488 12.4349 16.5237 11.8098C15.8986 11.1847 15.0507 10.8335 14.1667 10.8335C13.2826 10.8335 12.4348 11.1847 11.8096 11.8098C11.1845 12.4349 10.8333 13.2828 10.8333 14.1668C10.8333 15.0509 11.1845 15.8987 11.8096 16.5239C12.4348 17.149 13.2826 17.5002 14.1667 17.5002Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.1667 10.8335V14.1668H17.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 2.5V5.83333C10 6.05435 10.0878 6.26631 10.2441 6.42259C10.4004 6.57887 10.6123 6.66667 10.8333 6.66667H14.1667" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.58333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H10L14.1667 6.66667V8.33333" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ViewIcon = ({fill = "none", stroke = '#283745'}) => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.875 10C6.875 9.1712 7.20424 8.37634 7.79029 7.79029C8.37634 7.20424 9.1712 6.875 10 6.875C10.8288 6.875 11.6237 7.20424 12.2097 7.79029C12.7958 8.37634 13.125 9.1712 13.125 10C13.125 10.8288 12.7958 11.6237 12.2097 12.2097C11.6237 12.7958 10.8288 13.125 10 13.125C9.1712 13.125 8.37634 12.7958 7.79029 12.2097C7.20424 11.6237 6.875 10.8288 6.875 10ZM10 8.125C9.50272 8.125 9.02581 8.32254 8.67417 8.67417C8.32254 9.02581 8.125 9.50272 8.125 10C8.125 10.4973 8.32254 10.9742 8.67417 11.3258C9.02581 11.6775 9.50272 11.875 10 11.875C10.4973 11.875 10.9742 11.6775 11.3258 11.3258C11.6775 10.9742 11.875 10.4973 11.875 10C11.875 9.50272 11.6775 9.02581 11.3258 8.67417C10.9742 8.32254 10.4973 8.125 10 8.125Z" fill={stroke}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.6025 8.87167C3.25333 9.375 3.125 9.76917 3.125 10C3.125 10.2308 3.25333 10.625 3.6025 11.1283C3.94083 11.6142 4.4425 12.1417 5.0775 12.6292C6.35 13.6058 8.09417 14.375 10 14.375C11.9058 14.375 13.65 13.6058 14.9225 12.6292C15.5575 12.1417 16.0592 11.6142 16.3975 11.1283C16.7467 10.625 16.875 10.2308 16.875 10C16.875 9.76917 16.7467 9.375 16.3975 8.87167C16.0592 8.38583 15.5575 7.85833 14.9225 7.37083C13.65 6.39417 11.9058 5.625 10 5.625C8.09417 5.625 6.35 6.39417 5.0775 7.37083C4.4425 7.85833 3.94083 8.38583 3.6025 8.87167ZM4.31583 6.37917C5.75833 5.2725 7.76333 4.375 10 4.375C12.2367 4.375 14.2417 5.2725 15.6833 6.37917C16.4058 6.93333 17.0025 7.55167 17.4242 8.15917C17.8342 8.75 18.125 9.3975 18.125 10C18.125 10.6025 17.8333 11.25 17.4242 11.8408C17.0025 12.4483 16.4058 13.0658 15.6842 13.6208C14.2425 14.7275 12.2367 15.625 10 15.625C7.76333 15.625 5.75833 14.7275 4.31667 13.6208C3.59417 13.0667 2.9975 12.4483 2.57583 11.8408C2.16667 11.25 1.875 10.6025 1.875 10C1.875 9.3975 2.16667 8.75 2.57583 8.15917C2.9975 7.55167 3.59417 6.93417 4.31583 6.37917Z" fill={stroke}/>
        </svg>
    )
}

export const EditIcon = ({fill = "none", stroke = '#283745'}) => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.8796 4.51376C17.9587 4.63393 17.994 4.77775 17.9794 4.92092C17.9649 5.06409 17.9013 5.19784 17.7996 5.29959L10.1387 12.9596C10.0604 13.0379 9.96258 13.094 9.8554 13.1221L6.66457 13.9554C6.55909 13.9829 6.44826 13.9824 6.34306 13.9538C6.23787 13.9253 6.14197 13.8697 6.06489 13.7926C5.98782 13.7155 5.93224 13.6196 5.90368 13.5144C5.87511 13.4092 5.87456 13.2984 5.90207 13.1929L6.7354 10.0029C6.75973 9.90717 6.80562 9.81824 6.86957 9.74293L14.5587 2.05876C14.6759 1.94172 14.8348 1.87598 15.0004 1.87598C15.166 1.87598 15.3249 1.94172 15.4421 2.05876L17.7996 4.41543C17.8295 4.44543 17.8563 4.47838 17.8796 4.51376ZM16.4737 4.85709L15.0004 3.38459L7.90207 10.4829L7.38123 12.4771L9.3754 11.9563L16.4737 4.85709Z" fill={stroke}/>
            <path d="M16.3676 14.2997C16.5954 12.353 16.6681 10.3913 16.5851 8.43308C16.5833 8.38694 16.591 8.34091 16.6077 8.29789C16.6245 8.25486 16.65 8.21578 16.6826 8.18308L17.5026 7.36308C17.525 7.34054 17.5535 7.32496 17.5845 7.3182C17.6156 7.31144 17.6479 7.31378 17.6777 7.32496C17.7074 7.33614 17.7333 7.35567 17.7522 7.3812C17.7711 7.40674 17.7823 7.4372 17.7843 7.46891C17.9386 9.79494 17.88 12.1302 17.6093 14.4456C17.4126 16.1306 16.0593 17.4514 14.3818 17.6389C11.4696 17.9614 8.53067 17.9614 5.61846 17.6389C3.94179 17.4514 2.58762 16.1306 2.39096 14.4456C2.04546 11.4918 2.04546 8.50773 2.39096 5.55391C2.58762 3.86891 3.94096 2.54808 5.61846 2.36058C7.82876 2.11548 10.0557 2.0561 12.276 2.18308C12.3077 2.18536 12.3382 2.1967 12.3637 2.21575C12.3892 2.23481 12.4088 2.26078 12.42 2.29059C12.4312 2.32041 12.4337 2.35281 12.427 2.38397C12.4204 2.41513 12.405 2.44374 12.3826 2.46641L11.5551 3.29308C11.5227 3.32537 11.484 3.35068 11.4415 3.36745C11.3989 3.38421 11.3533 3.39209 11.3076 3.39058C9.45492 3.3276 7.60009 3.39861 5.75762 3.60308C5.21924 3.66267 4.71667 3.90201 4.33108 4.28245C3.9455 4.66289 3.69943 5.16221 3.63262 5.69974C3.2985 8.55667 3.2985 11.4428 3.63262 14.2997C3.69943 14.8373 3.9455 15.3366 4.33108 15.717C4.71667 16.0975 5.21924 16.3368 5.75762 16.3964C8.5776 16.7089 11.4235 16.7089 14.2435 16.3964C14.7818 16.3368 15.2844 16.0975 15.67 15.717C16.0556 15.3366 16.3008 14.8373 16.3676 14.2997Z" fill={stroke}/>
        </svg>
    )
}

export const UploadIcon = ({width, height}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2086 26.2541C8.40498 26.2601 6.66816 25.5719 5.35822 24.332C4.7266 23.7348 4.22281 23.0156 3.87738 22.2179C3.53194 21.4203 3.35206 20.5608 3.34863 19.6916C3.34863 17.9518 4.07197 16.282 5.35822 15.0512C6.66816 13.8113 8.40498 13.123 10.2086 13.1291C10.6388 11.2143 11.8959 9.53283 13.7043 8.45221C14.6141 7.91168 15.6148 7.54153 16.6574 7.35991C17.7141 7.17401 18.7954 7.17697 19.8511 7.36866C20.904 7.55971 21.9045 7.93741 22.797 8.477C23.673 9.00253 24.4374 9.69468 25.0472 10.5143C25.6422 11.3207 26.0534 12.2235 26.2605 13.1728C26.4676 14.1207 26.4647 15.0978 26.2503 16.0457H27.7086C29.0623 16.0457 30.3606 16.5835 31.3178 17.5407C32.275 18.4979 32.8128 19.7962 32.8128 21.1499C32.8128 22.5036 32.275 23.8019 31.3178 24.7591C30.3606 25.7163 29.0623 26.2541 27.7086 26.2541H26.2503" stroke="black" strokeWidth="0.958333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.125 21.875L17.5 17.5L21.875 21.875" stroke="black" strokeWidth="0.958333" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.5 17.5V30.625" stroke="black" strokeWidth="0.958333" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const BookmarkIcon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M6.73 19.7C7.55 18.82 8.8 18.89 9.52 19.85L10.53 21.2C11.34 22.27 12.65 22.27 13.46 21.2L14.47 19.85C15.19 18.89 16.44 18.82 17.26 19.7C19.04 21.6 20.49 20.97 20.49 18.31V7.04C20.5 3.01 19.56 2 15.78 2H8.22C4.44 2 3.5 3.01 3.5 7.04V18.3C3.5 20.97 4.96 21.59 6.73 19.7Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 7H16" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 11H15" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const RecentIcon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.71 15.18L12.61 13.33C12.07 13.01 11.63 12.24 11.63 11.61V7.51001" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const CameraIcon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M4.50667 14.6666H11.4933C13.3333 14.6666 14.0667 13.5399 14.1533 12.1666L14.5 6.65992C14.5933 5.21992 13.4467 3.99992 12 3.99992C11.5933 3.99992 11.22 3.76659 11.0333 3.40659L10.5533 2.43992C10.2467 1.83325 9.44667 1.33325 8.76667 1.33325H7.24C6.55334 1.33325 5.75334 1.83325 5.44667 2.43992L4.96667 3.40659C4.78 3.76659 4.40667 3.99992 4 3.99992C2.55334 3.99992 1.40667 5.21992 1.5 6.65992L1.84667 12.1666C1.92667 13.5399 2.66667 14.6666 4.50667 14.6666Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7 5.33325H9" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 12.0001C9.19334 12.0001 10.1667 11.0267 10.1667 9.83341C10.1667 8.64008 9.19334 7.66675 8 7.66675C6.80667 7.66675 5.83334 8.64008 5.83334 9.83341C5.83334 11.0267 6.80667 12.0001 8 12.0001Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const Edit2Icon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M8.84 2.40006L3.36667 8.1934C3.16 8.4134 2.96 8.84673 2.92 9.14673L2.67333 11.3067C2.58667 12.0867 3.14667 12.6201 3.92 12.4867L6.06667 12.1201C6.36667 12.0667 6.78667 11.8467 6.99333 11.6201L12.4667 5.82673C13.4133 4.82673 13.84 3.68673 12.3667 2.2934C10.9 0.913397 9.78667 1.40006 8.84 2.40006Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.92667 3.3667C8.21333 5.2067 9.70667 6.61337 11.56 6.80003" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 14.6667H14" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const SaveIcon = ({width, height, type}) => {
    return(
        type === 'active' ? 
        <svg width={width} height={height} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.78 0H5.22C1.44 0 0.5 1.01 0.5 5.04V16.3C0.5 18.96 1.96 19.59 3.73 17.69L3.74 17.68C4.56 16.81 5.81 16.88 6.52 17.83L7.53 19.18C8.34 20.25 9.65 20.25 10.46 19.18L11.47 17.83C12.19 16.87 13.44 16.8 14.26 17.68C16.04 19.58 17.49 18.95 17.49 16.29V5.04C17.5 1.01 16.56 0 12.78 0ZM11.75 8.75H6.25C5.84 8.75 5.5 8.41 5.5 8C5.5 7.59 5.84 7.25 6.25 7.25H11.75C12.16 7.25 12.5 7.59 12.5 8C12.5 8.41 12.16 8.75 11.75 8.75Z" fill="#1893F1"/>
        </svg>
        :
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.72827 19.7C7.54827 18.82 8.79828 18.89 9.51828 19.85L10.5283 21.2C11.3383 22.27 12.6483 22.27 13.4583 21.2L14.4683 19.85C15.1883 18.89 16.4383 18.82 17.2583 19.7C19.0383 21.6 20.4883 20.97 20.4883 18.31V7.04C20.4883 3.01 19.5483 2 15.7683 2H8.20828C4.42828 2 3.48828 3.01 3.48828 7.04V18.3C3.49828 20.97 4.95827 21.59 6.72827 19.7Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.25 10H14.75" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}

export const ArticleIcon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M14.5 4.5V6.5C14.5 7.6 15.4 8.5 16.5 8.5H18.5" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 13H12" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 17H16" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const PostedArticleIcon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M9.31006 14.7L10.8101 16.2L14.8101 12.2" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16 4.02C19.33 4.2 21 5.43 21 10V16C21 20 20 22 15 22H9C4 22 3 20 3 16V10C3 5.44 4.67 4.2 8 4.02" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const SavedArticleIcon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M14 16.16L10.04 12.2" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M13.96 12.24L10 16.2" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10 6H14C16 6 16 5 16 4C16 2 15 2 14 2H10C9 2 8 2 8 4C8 6 9 6 10 6Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16 4.02C19.33 4.2 21 5.43 21 10V16C21 20 20 22 15 22H9C4 22 3 20 3 16V10C3 5.44 4.67 4.2 8 4.02" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}
export const ReactionIcon = ({width, height, type}) => {
    return(
        type === "active" ?
            <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM8.5 6.38C9.53 6.38 10.38 7.22 10.38 8.26C10.38 9.3 9.54 10.14 8.5 10.14C7.46 10.14 6.62 9.28 6.62 8.25C6.62 7.22 7.47 6.38 8.5 6.38ZM12 19.08C9.31 19.08 7.12 16.89 7.12 14.2C7.12 13.5 7.69 12.92 8.39 12.92H15.59C16.29 12.92 16.86 13.49 16.86 14.2C16.88 16.89 14.69 19.08 12 19.08ZM15.5 10.12C14.47 10.12 13.62 9.28 13.62 8.24C13.62 7.2 14.46 6.36 15.5 6.36C16.54 6.36 17.38 7.2 17.38 8.24C17.38 9.28 16.53 10.12 15.5 10.12Z" fill="#1893F1"/>
            </svg>
        :
            <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M15.5 9.75C16.3284 9.75 17 9.07843 17 8.25C17 7.42157 16.3284 6.75 15.5 6.75C14.6716 6.75 14 7.42157 14 8.25C14 9.07843 14.6716 9.75 15.5 9.75Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.5 9.75C9.32843 9.75 10 9.07843 10 8.25C10 7.42157 9.32843 6.75 8.5 6.75C7.67157 6.75 7 7.42157 7 8.25C7 9.07843 7.67157 9.75 8.5 9.75Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.4 13.3H15.6C16.1 13.3 16.5 13.7 16.5 14.2C16.5 16.69 14.49 18.7 12 18.7C9.51 18.7 7.5 16.69 7.5 14.2C7.5 13.7 7.9 13.3 8.4 13.3Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        
        
    )
}

export const CommentIcon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M5.66666 7H10.3333" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.66668 12.2867H7.33334L10.3 14.26C10.74 14.5533 11.3333 14.24 11.3333 13.7067V12.2867C13.3333 12.2867 14.6667 10.9533 14.6667 8.95333V4.95333C14.6667 2.95333 13.3333 1.62 11.3333 1.62H4.66668C2.66668 1.62 1.33334 2.95333 1.33334 4.95333V8.95333C1.33334 10.9533 2.66668 12.2867 4.66668 12.2867Z" stroke={stroke} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const SendIcon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M7.40005 6.32003L15.8901 3.49003C19.7001 2.22003 21.7701 4.30003 20.5101 8.11003L17.6801 16.6C15.7801 22.31 12.6601 22.31 10.7601 16.6L9.92005 14.08L7.40005 13.24C1.69005 11.34 1.69005 8.23003 7.40005 6.32003Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.11 13.6501L13.69 10.0601" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const DeleteIcon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.85 9.14001L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79002C6.00002 22 5.91002 20.78 5.80002 19.21L5.15002 9.14001" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M10.33 16.5H13.66" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.5 12.5H14.5" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const CopyIcon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const SettingIcon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2 12.88V11.12C2 10.08 2.85 9.22 3.9 9.22C5.71 9.22 6.45 7.94 5.54 6.37C5.02 5.47 5.33 4.3 6.24 3.78L7.97 2.79C8.76 2.32 9.78 2.6 10.25 3.39L10.36 3.58C11.26 5.15 12.74 5.15 13.65 3.58L13.76 3.39C14.23 2.6 15.25 2.32 16.04 2.79L17.77 3.78C18.68 4.3 18.99 5.47 18.47 6.37C17.56 7.94 18.3 9.22 20.11 9.22C21.15 9.22 22.01 10.07 22.01 11.12V12.88C22.01 13.92 21.16 14.78 20.11 14.78C18.3 14.78 17.56 16.06 18.47 17.63C18.99 18.54 18.68 19.7 17.77 20.22L16.04 21.21C15.25 21.68 14.23 21.4 13.76 20.61L13.65 20.42C12.75 18.85 11.27 18.85 10.36 20.42L10.25 20.61C9.78 21.4 8.76 21.68 7.97 21.21L6.24 20.22C5.33 19.7 5.02 18.53 5.54 17.63C6.45 16.06 5.71 14.78 3.9 14.78C2.85 14.78 2 13.92 2 12.88Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const CategoryIcon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M5 10H7C9 10 10 9 10 7V5C10 3 9 2 7 2H5C3 2 2 3 2 5V7C2 9 3 10 5 10Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17 22H19C21 22 22 21 22 19V17C22 15 21 14 19 14H17C15 14 14 15 14 17V19C14 21 15 22 17 22Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14H5C3 14 2 15 2 17V19C2 21 3 22 5 22Z" stroke={stroke} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const UserProfileIcon = ({width, height, fill, stroke}) => {
    return(
        <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M12.12 12.78C12.05 12.77 11.96 12.77 11.88 12.78C10.12 12.72 8.72 11.28 8.72 9.50998C8.72 7.69998 10.18 6.22998 12 6.22998C13.81 6.22998 15.28 7.69998 15.28 9.50998C15.27 11.28 13.88 12.72 12.12 12.78Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M18.74 19.3801C16.96 21.0101 14.6 22.0001 12 22.0001C9.40001 22.0001 7.04001 21.0101 5.26001 19.3801C5.36001 18.4401 5.96001 17.5201 7.03001 16.8001C9.77001 14.9801 14.25 14.9801 16.97 16.8001C18.04 17.5201 18.64 18.4401 18.74 19.3801Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ExpandIcon = ({width, height, fill, stroke}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99998 14.6666H9.99998C13.3333 14.6666 14.6666 13.3333 14.6666 9.99992V5.99992C14.6666 2.66659 13.3333 1.33325 9.99998 1.33325H5.99998C2.66665 1.33325 1.33331 2.66659 1.33331 5.99992V9.99992C1.33331 13.3333 2.66665 14.6666 5.99998 14.6666Z" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 4L4 12" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12 6.66667V4H9.33331" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 9.33325V11.9999H6.66667" stroke={stroke} strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

